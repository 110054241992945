<template>
    <div class="container">
        <br>
        <h1 style="color: #594E42">Under Construction</h1>
    </div>
</template>

<script>
export default {
    name: 'Body'
}
</script>

<style scoped>

</style>
